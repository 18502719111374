<template>
  <v-sheet
    dark
    tile
    class="viewer"
    color="grey darken-3"
    @mousemove="showToolbar"
    @mouseleave="hideToolbar"
    @touchstart="showToolbar"
    @touchmove="showToolbar"
  >
    <template v-if="loading && !loadingError">
      <v-progress-linear
        :indeterminate="progress === 0"
        color="primary"
        :value="progress * 100"
      />
      <div class="center">
        <div class="center">
          {{ $t("live.pdfViewer.loading") }}
        </div>
      </div>
    </template>
    <template v-if="loadingError || isLoadingSlow">
      <div class="center">
        <div class="center">
          {{ errorMessage }}
        </div>
        <eva-button
          @click="reloadFile"
          primary
        >
          {{ $t("live.pdfViewer.retry") }}
        </eva-button>
      </div>
    </template>
    <div
      class="pdf_container pl-2 pr-2"
      ref="pdf_embed"
    >
      <vue-pdf-embed
        class="mb-2 mt-2 pdf_page"
        :source="src"
        :key="componentKey"
        :width="pdfWidth"
        @progress="trackProgress"
        @loaded="onLoaded"
        @loading-failed="() => loadingError = true"
        @rendering-failed="() => loadingError = true"
      />


      <floating-toolbar :visible="isToolbarVisible && !loading">
        <v-btn
          @click.stop="zoom += 25"
          icon
        >
          <v-icon>zoom_in</v-icon>
        </v-btn>
        <v-btn
          @click.stop="zoom = 100"
          icon
        >
          <v-icon>search_off</v-icon>
        </v-btn>
        <v-btn
          @click.stop="zoom -= 25"
          icon
        >
          <v-icon>zoom_out</v-icon>
        </v-btn>
      </floating-toolbar>
    </div>
  </v-sheet>
</template>

<script>
import FloatingToolbar from "../../shared/floating_toolbar.vue"
import { useTimeoutFn } from "@vueuse/core"
import { ref } from "vue"

const VuePdfEmbed = () => import ("vue-pdf-embed/dist/vue2-pdf-embed.js")

export default {
  name: "PdfViewer",
  components: {
    FloatingToolbar,
    VuePdfEmbed,
  },
  setup() {
    const isToolbarVisible = ref(false)
    const { start: startHideTimer, stop: stopHideTimer } = useTimeoutFn(() => {
      isToolbarVisible.value = false
    }, 5000)

    const showToolbar = () => {
      isToolbarVisible.value = true
      stopHideTimer()
      startHideTimer()
    }

    const hideToolbar = () => {
      isToolbarVisible.value = false
      stopHideTimer()
    }

    return {
      showToolbar,
      hideToolbar,
      isToolbarVisible,
    }
  },
  data: () => ({
    progress: 0,
    zoom: 100,
    elementWidth: 400,
    loading: true,
    componentKey: 0,
    loadingError: false,
    isLoadingSlow: false,
  }),
  props: {
    src: {
      type: String,
      required: true,
    },
  },
  computed: {
    pdfWidth() {
      return this.elementWidth * (this.zoom / 100)
    },
    errorMessage() {
      if (this.loadingError) {
        return this.$t("live.pdfViewer.error")
      }
      return this.$t("live.pdfViewer.slowLoading")
    },
  },
  methods: {
    trackProgress({ loaded, total }) {
      if (this.loading && !this.loadingError && !this.isLoadingSlow) {
        this.progress = loaded / total
      }
    },
    reset() {
      this.progress = 0
      this.zoom = 100
      this.loading = true
      this.loadingError = false
      this.isLoadingSlow = false
      this.updateComponentKey()
      setTimeout(() => {
        this.updateComponentKey()
      }, 1)

      this.startLoadingTimeout()
    },
    reloadFile() {
      this.updateComponentKey()
      this.loadingError = false
      this.isLoadingSlow = false
      this.loading = true
      this.progress = 0

      this.startLoadingTimeout()
    },
    startLoadingTimeout() {
      const { start } = useTimeoutFn(() => {
        if (this.loading) {
          this.isLoadingSlow = true
        }
      }, 5000)
      start()
    },
    onLoaded() {
      this.loading = false
      this.loadingError = false
      this.isLoadingSlow = false
    },
    updateComponentKey() {
      this.componentKey += 1
    },
  },
  watch: {
    src: {
      handler() {
        this.reset()
      },
      immediate: true,
    },
  },
  mounted() {
    this.$nextTick(() => {
      const pdf_embed = this.$refs.pdf_embed
      if(pdf_embed) {
        this.elementWidth = pdf_embed.clientWidth - 16

        // If the pdf is not loaded after 500ms, reset the component.
        // This is a dirty fix for the issue where the pdf is not loaded after the component is mounted.
        // This issue happens when switching between several pdfs quickly when the pdf is not loaded yet.
        setTimeout(() => {
          if(this.loading) {
            this.reset()
          }
        }, 500)
      }
    })
  },
}
</script>

<style scoped>
  .pdf_container{
    height: 100%;
    overflow: scroll;
  }

  .viewer {
    height: 100%;
  }

  .pdf_page::v-deep canvas {
    margin-left: auto;
    margin-right: auto;
  }

</style>
